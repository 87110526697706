













































import {Component, Vue, Watch} from 'vue-property-decorator';
import {Route} from 'vue-router';
import {ApplicationModule} from '@/store/modules/application';
import {UserModule} from '@/store/modules/user';

@Component({})
export default class Login extends Vue {
    private qrcodeLogin!: string;

    private email = '';

    private password = '';

    private hidePassword = true;

    private error = '';

    private get loading() {
        return ApplicationModule.loading;
    }

    private created() {
        this.qrcodeLogin = this.$route.params.qrcodeLogin;
        if (this.qrcodeLogin !== undefined) {
            this.anonymousLogin();
        }
    }

    @Watch('$route')
    private beforeRouteUpdate(to: Route, from: Route) {
        if (from.params.qrcodeLogin !== to.params.qrcodeLogin) {
            this.qrcodeLogin = to.params.qrcodeLogin;
            if (this.qrcodeLogin !== undefined) {
                this.anonymousLogin();
            }
        }
    }

    private async login(event: Event) {
        event.preventDefault();
        await UserModule.login({username: this.email, password: this.password});
        if (UserModule.authenticated) {
            this.$router.replace((this.$route.query.redirect as string) || '/');
        }
    }

    private async anonymousLogin() {
        await UserModule.qrCodeLogin(this.qrcodeLogin);
        if (UserModule.authenticated) {
            this.$router.replace((this.$route.query.redirect as string) || '/');
        }
    }
}
